import React, { useEffect, useReducer, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import { Typography } from '@material-ui/core';
import ProductFormStep from '../steps/form/ProductFormStep';
import ProductPricesStep from '../steps/price/ProductPricesStep';
import ProductDescription from '../description/ProductDescription';
import ProductDoneStep from '../steps/done/ProductDoneStep';
import Loading from '../../../../components/loading/Loading';
import ProductAction from './ProductAction';
import { ProductStepType, steps as originalSteps } from 'pages/products/registration/steps';
import ProductIngredientStep from '../steps/ingredients/ProductIngredientStep';
import ProductAdditionalStep from '../steps/additional/ProductAdditionalStep';
import productReducer, { INITIAL_STATE } from 'store/modules/product/reducer';
import { productChange, setCategory, setProduct, setIsPizza } from 'store/modules/product/actions';
import history from 'services/history';
import ProductComplement from '../steps/complement/ProductComplement';
import ProductComplementPizza from '../steps/complement_pizza/ProductComplement';
import ImagePreview from 'components/image-preview/ImagePreview';
import ProductAvailability from '../steps/availability/ProductAvailability';
import ProductPrintersStep from '../steps/printers/ProductPrintersStep';
import { useMessaging } from 'hooks/messaging';
import { Category } from 'types/category';
import { Additional, Ingredient, Product as ProductType } from 'types/product';
import { ProductContextProvider, ProductContextValue } from '../hooks/useProduct';
import { useProductValidation } from '../validation/ProductValidation';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import PageHeader from 'components/page-header/PageHeader';
import ProductButtons from './ProductButtons';
import { useFetchPrinters } from 'pages/products/hooks/useFetchPrinters';
import { useFetchCategories } from 'pages/products/hooks/useFetchCategories';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useFetchTaxRules } from 'pages/tax-rules/hooks/useFetchTaxRules';
import ProductFiscalStep from '../steps/fiscal/ProductFiscalStep';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 30,
  },
  productDescription: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 10,
    top: 0,
    bottom: 0,
    width: 300,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 60,
    borderLeft: '1px solid #eee',
    overflowY: 'auto',
  },
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70vh',
    borderRadius: 4,
    marginBottom: 40,
    position: 'relative',
    width: 'calc(100% - 300px)',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: 0,
      marginRight: 0,
      width: '100%',
    },
  },
}));

const Product: React.FC = () => {
  const [step, setStep] = useState(2);
  const classes = useStyles();
  const [product, dispatch] = useReducer(productReducer, INITIAL_STATE);
  const [categories] = useFetchCategories();
  const [loading, setLoading] = useState(true);
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [printers] = useFetchPrinters();
  const [validation, , validateForm, validatePrices] = useProductValidation();
  const [steps, setSteps] = useState<ProductStepType[]>(originalSteps.slice());
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [additional, setAdditional] = useState<Additional[]>([]);
  const { taxRules, fetch: fetchTaxRules } = useFetchTaxRules();
  const { id } = useParams<{ id: string }>();
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    fetchTaxRules('');
  }, [fetchTaxRules]);

  useEffect(() => {
    let request = true;
    api
      .get('/products/' + id)
      .then(response => {
        if (!request) {
          return;
        }

        dispatch(setProduct(response.data));
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível carregar o produto',
        });

        history.push('/menu/products');
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }
      });

    return () => {
      request = false;
    };
  }, [id, handleOpen, showErrorDialog]);

  useEffect(() => {
    if (!product.category.id) {
      return;
    }

    const hasStepIngredient = steps.some(step => step.id === 'STEP_4');
    const hasStepAdditional = steps.some(step => step.id === 'STEP_5');

    if (hasStepIngredient)
      api.get('/ingredients').then(response => {
        setIngredients(response.data.ingredients.data);
      });

    if (hasStepAdditional)
      api
        .get('/productAdditional', {
          params: {
            q: 'no-edge',
            category_id: product.category.id,
          },
        })
        .then(response => {
          setAdditional(response.data.data);
        });
  }, [product.category, steps]);

  const currentStep = useMemo(() => {
    return steps.find(item => item.order === step);
  }, [step, steps]);

  function handleSubmit() {
    setSaving(true);

    const payload = {
      ...product,
      ingredients: product.ingredients.filter(additional => additional.selected).map(ingredient => ingredient.id),
      additional: product.additional.filter(additional => additional.selected).map(additional => additional.id),
    };

    api
      .put('/products/' + product.id, payload)
      .then(() => {
        handleOpen('Salvo');
        history.push('/menu/products');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar o produto',
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  useEffect(() => {
    if (!product.category.id) {
      return;
    }

    let newSteps = originalSteps.slice();
    let order = 0;

    if (product.category.is_pizza) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_3');
      dispatch(productChange('price', 0));
    }
    if (!product.category.has_ingredient) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_4');
    }
    if (!product.category.has_additional) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_5');
    }
    if (!product.category.has_complement) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_6');
    }

    setSteps(
      newSteps.map(step => {
        order++;
        step.order = order;
        return step;
      })
    );
  }, [product.category]);

  function handleSetCategory(category: Category) {
    dispatch(setCategory(category));
    if (category.is_pizza) dispatch(setIsPizza());
    handleNext();
  }

  function handleChange(value, index: keyof ProductType) {
    dispatch(productChange(index, value));
  }

  async function handleValidationForm() {
    validateForm(product);
  }

  async function handlePriceValidation() {
    validatePrices(product);
  }

  async function handleNext() {
    if (currentStep?.id === 'STEP_2') {
      validateForm(product)
        .then(() => setStep(state => state + 1))
        .catch(err => console.error(err));

      return;
    }

    if (currentStep?.id === 'STEP_3') {
      validatePrices(product)
        .then(() => setStep(state => state + 1))
        .catch(err => console.error(err));

      return;
    }

    setStep(state => state + 1);
  }

  function handlePrior() {
    if (step > 2) {
      setStep(step - 1);
      return;
    }

    history.push('/menu/products');
  }

  function handleSetStep(order: number) {
    setStep(order);
  }

  function handleGetPizzaTasteComplements() {
    const tasteCategory = product.complement_categories.find(category => category.is_pizza_taste);
    return tasteCategory?.complements;
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete('/products/' + product.id)
      .then(() => {
        handleOpen('Excluído');
        setSaving(false);
        history.push('/menu/products');
      })
      .catch(() => {
        setSaving(false);
        handleOpen('Não foi possível excluir o produto');
      });
  }

  function handleStatus() {
    setSaving(true);

    api
      .put(`/products/status/${product.id}`)
      .then(() => {
        handleOpen('Atualizado');
        handleChange(!product.activated, 'activated');
        history.push('/menu/products');
        setSaving(false);
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível alterar o produto');
        setSaving(false);
      });
  }

  function createProductContextValue(): ProductContextValue {
    return {
      dispatch,
      product,
      handleGetPizzaTasteComplements,
      currentStep,
      handleChange,
      handleFormValidation: handleValidationForm,
      handleNext,
      handlePriceValidation,
      handlePrior,
      handleSetCategory,
      handleSetStep,
      setImagePreview: state => setImagePreview(state),
      step,
      steps,
      validation,
      categories,
      loading,
      ingredients,
      additional,
    };
  }

  return (
    <ProductContextProvider value={createProductContextValue()}>
      {imagePreview && (
        <ImagePreview
          onExited={() => setImagePreview(false)}
          src={product?.image?.imageUrl}
          description={product.name}
        />
      )}

      {saving && <Loading />}

      <CustomAppbar
        title="Editar produto"
        ActionComponents={<ProductAction handleDelete={handleDelete} handleStatus={handleStatus} />}
      />

      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <div className={classes.productDescription}>
            <ProductDescription />
          </div>

          <div className={classes.title}>
            <PageHeader title="Editar produto" backAction={handlePrior} />

            <Typography variant="body1">
              <span className={classes.step}>{currentStep?.order}</span>
              {currentStep?.description}
            </Typography>
          </div>

          <div className={classes.container}>
            {currentStep?.id === 'STEP_2' && <ProductFormStep />}
            {currentStep?.id === 'STEP_3' && <ProductPricesStep />}
            {currentStep?.id === 'STEP_4' && <ProductIngredientStep />}
            {currentStep?.id === 'STEP_5' && <ProductAdditionalStep />}
            {currentStep?.id === 'STEP_6' && product.category.is_pizza ? (
              <ProductComplementPizza />
            ) : (
              currentStep?.id === 'STEP_6' && !product.category.is_pizza && <ProductComplement />
            )}
            {currentStep?.id === 'STEP_7' && <ProductAvailability availability={product.availability} />}
            {currentStep?.id === 'STEP_8' && <ProductFiscalStep taxRules={taxRules} />}
            {currentStep?.id === 'STEP_9' && <ProductPrintersStep printers={printers} />}
            {currentStep?.id === 'STEP_10' && <ProductDoneStep saving={saving} handleSubmit={handleSubmit} />}

            {currentStep && <ProductButtons />}
          </div>
        </>
      )}
    </ProductContextProvider>
  );
};

export default Product;
