import { useCallback, useState } from 'react';
import { OrdersQueryParams } from '../Orders';
import { CancelTokenSource } from 'axios';
import { api } from 'services/api';
import { OrderList } from '../types/orderList';
import { useOrderFormatter } from './useOrderFormatter';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const queryParamsInitialValue: OrdersQueryParams = {
  status: 'all',
  initial_date: new Date(),
  final_date: new Date(),
  page: 0,
  rows: 20,
};

export function useFetchOrders() {
  const [queryParams, setQueryParams] = useState<OrdersQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrderList[]>([]);
  const [formatter] = useOrderFormatter();
  const [total, setTotal] = useState(0);
  const { showErrorDialog } = useErrorHandler();

  const fetch = useCallback(
    (term: string, cancelToken?: CancelTokenSource) => {
      setLoading(true);

      api
        .get('orders', {
          params: { ...queryParams, page: queryParams.page + 1, term, type: 'delivery' },
          cancelToken: cancelToken?.token,
        })
        .then(response => {
          setOrders(formatter(response.data.data));
          setTotal(response.data.total);
        })
        .catch(error => {
          showErrorDialog({
            error,
            message: 'Não foi possível recuperar os pedidos',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [formatter, showErrorDialog, queryParams]
  );

  return {
    fetch,
    loading,
    total,
    queryParams,
    setQueryParams,
    orders,
    setOrders,
  };
}
