import React, { useEffect, useReducer, useState, useMemo, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import { Typography } from '@material-ui/core';
import CategorySelectStep from '../steps/category/CategorySelectStep';
import ProductFormStep from '../steps/form/ProductFormStep';
import ProductPricesStep from '../steps/price/ProductPricesStep';
import ProductDescription from '../description/ProductDescription';
import ProductDoneStep from '../steps/done/ProductDoneStep';
import Loading from '../../../../components/loading/Loading';
import ProductAction from './ProductAction';
import { ProductStepType, steps as originalSteps } from 'pages/products/registration/steps';
import ProductIngredientStep from '../steps/ingredients/ProductIngredientStep';
import ProductAdditionalStep from '../steps/additional/ProductAdditionalStep';
import productReducer, { INITIAL_STATE } from 'store/modules/product/reducer';
import { productChange, setCategory, setIsPizza, importProduct } from 'store/modules/product/actions';
import history from 'services/history';
import ProductComplement from '../steps/complement/ProductComplement';
import ProductComplementPizza from '../steps/complement_pizza/ProductComplement';
import ImagePreview from 'components/image-preview/ImagePreview';
import ProductImport from '../import/ProductImport';
import ProductAvailability from '../steps/availability/ProductAvailability';
import ProductPrintersStep from '../steps/printers/ProductPrintersStep';
import { useMessaging } from 'hooks/messaging';
import { Category } from 'types/category';
import { Additional, Ingredient, Product as ProductType } from 'types/product';
import { ProductContextProvider, ProductContextValue } from '../hooks/useProduct';
import { useProductValidation } from '../validation/ProductValidation';
import PageHeader from 'components/page-header/PageHeader';
import ProductButtons from './ProductButtons';
import { useFetchCategories } from 'pages/products/hooks/useFetchCategories';
import { useFetchPrinters } from 'pages/products/hooks/useFetchPrinters';
import ProductFiscalStep from '../steps/fiscal/ProductFiscalStep';
import { useFetchTaxRules } from 'pages/tax-rules/hooks/useFetchTaxRules';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 30,
  },
  productDescription: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 10,
    top: 0,
    bottom: 0,
    width: 300,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 60,
    borderLeft: '1px solid #eee',
    overflowY: 'auto',
    '& .resizable-border': {
      backgroundColor: '#ccc',
      position: 'absolute',
      left: 0,
      width: 4,
      height: '100%',
      cursor: 'w-resize',
      top: 0,
    },
  },
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70vh',
    borderRadius: 4,
    marginBottom: 100,
    position: 'relative',
    width: 'calc(100% - 300px)',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: 0,
      marginRight: 0,
      width: '100%',
    },
  },
}));

const Product: React.FC = () => {
  const [step, setStep] = useState(1);
  const [product, dispatch] = useReducer(productReducer, INITIAL_STATE);
  const [categories, , categoriesLoading] = useFetchCategories();
  const [printers, , printersLoading] = useFetchPrinters();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [dialogImport, setDialogImport] = useState(false);
  const [validation, , validateForm, validatePrices] = useProductValidation();
  const [steps, setSteps] = useState<ProductStepType[]>(originalSteps.slice());
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [additional, setAdditional] = useState<Additional[]>([]);
  const [initialPos, setInitialPos] = useState(0);
  const [initialSize, setInitialSize] = useState(300);
  const [width, setWidth] = useState(310);
  const classes = useStyles({ step });
  const { taxRules, fetch: fetchTaxRules } = useFetchTaxRules();
  const { showErrorDialog } = useErrorHandler();

  const loading = useMemo(() => categoriesLoading || printersLoading, [categoriesLoading, printersLoading]);

  useEffect(() => {
    fetchTaxRules('');
  }, [fetchTaxRules]);

  useEffect(() => {
    if (!product.category.id) {
      return;
    }

    const hasStepIngredient = steps.some(step => step.id === 'STEP_4');
    const hasStepAdditional = steps.some(step => step.id === 'STEP_5');

    if (hasStepIngredient)
      api.get('/ingredients').then(response => {
        setIngredients(response.data.ingredients.data);
      });

    if (hasStepAdditional)
      api
        .get('/productAdditional', {
          params: {
            q: 'no-edge',
            category_id: product.category.id,
          },
        })
        .then(response => {
          setAdditional(response.data.data);
        });
  }, [product.category, steps]);

  const currentStep = useMemo(() => {
    return steps.find(item => item.order === step);
  }, [step, steps]);

  function handleSubmit() {
    setSaving(true);

    let saved = false;

    const payload = {
      ...product,
      ingredients: product.ingredients.filter(additional => additional.selected).map(ingredient => ingredient.id),
      additional: product.additional.filter(additional => additional.selected).map(additional => additional.id),
    };

    api
      .post('/products', payload)
      .then(response => {
        if (response.status === 200) {
          messaging.handleOpen('Salvo');
          saved = true;
        }
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar o produto',
        });
      })
      .finally(() => {
        setSaving(false);
        if (saved) {
          history.push('/menu/products');
        }
      });
  }

  function handleSetCategory(category: Category) {
    dispatch(setCategory(category));
    handleSetSteps(category);
    if (category.is_pizza) dispatch(setIsPizza());
    handleNext();
  }

  function handleSetSteps(category: Category) {
    let newSteps = originalSteps.slice();
    let order = 0;

    if (category.is_pizza) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_3');
      dispatch(productChange('price', 0));
    }
    if (!category.has_ingredient) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_4');
    }
    if (!category.has_additional) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_5');
    }
    if (!category.has_complement) {
      newSteps = newSteps.filter(step => step.id !== 'STEP_6');
    }

    setSteps(
      newSteps.map(step => {
        order++;
        step.order = order;
        return step;
      })
    );
  }

  function handleChange(value: any, index: keyof ProductType) {
    dispatch(productChange(index, value));
  }

  async function handleValidationForm() {
    await validateForm(product);
  }

  async function handlePriceValidation() {
    await validatePrices(product);
  }

  async function handleNext() {
    if (currentStep?.id === 'STEP_2') {
      handleValidationForm()
        .then(() => setStep(state => state + 1))
        .catch(err => console.error(err));
      return;
    }

    if (currentStep?.id === 'STEP_3') {
      handlePriceValidation()
        .then(() => setStep(state => state + 1))
        .catch(err => console.error(err));
      return;
    }

    setStep(step + 1);
  }

  function handlePrior() {
    if (step > 1) {
      setStep(step - 1);
      return;
    }

    history.push('/menu/products');
  }

  function handleSetStep(order: number) {
    setStep(order);
  }

  function handleImportProduct(product: ProductType) {
    dispatch(importProduct(product));
  }

  function handleGetPizzaTasteComplements() {
    const tasteCategory = product.complement_categories.find(category => category.is_pizza_taste);
    return tasteCategory?.complements;
  }

  function initial(event: MouseEvent<HTMLDivElement>) {
    const resizable = document.getElementById('resizable');

    if (!resizable) {
      return;
    }

    setInitialPos(event.clientX);
    setInitialSize(resizable.offsetWidth);
    setWidth(resizable.offsetWidth);
  }

  function resize(event: MouseEvent<HTMLDivElement>) {
    const resizable = document.getElementById('resizable');
    const container = document.getElementById('container');

    if (!resizable || !container) {
      return;
    }

    if (event.clientX === 0) {
      return;
    }

    const value = (event.clientX - initialPos) * -1;

    const width = initialSize + value;

    resizable.style.width = `${width}px`;
    container.style.width = `calc(100% - ${width}px`;

    setWidth(width + 10);
  }

  function createProductContextValue(): ProductContextValue {
    return {
      dispatch,
      product,
      handleGetPizzaTasteComplements,
      currentStep,
      handleChange,
      handleFormValidation: handleValidationForm,
      handleNext,
      handlePriceValidation,
      handlePrior,
      handleSetCategory,
      handleSetStep,
      setImagePreview: state => setImagePreview(state),
      step,
      steps,
      validation,
      categories,
      loading,
      ingredients,
      additional,
    };
  }

  return (
    <ProductContextProvider value={createProductContextValue()}>
      {imagePreview && (
        <ImagePreview
          onExited={() => setImagePreview(false)}
          src={product?.image?.imageUrl}
          description={product.name}
        />
      )}

      {dialogImport && (
        <ProductImport
          handleSetStep={handleSetStep}
          handleSetSteps={handleSetSteps}
          onExited={() => setDialogImport(false)}
          handleImportProduct={handleImportProduct}
        />
      )}

      {saving && <Loading />}

      <CustomAppbar
        title="Novo produto"
        ActionComponents={<ProductAction openDialogImport={() => setDialogImport(true)} />}
      />

      <div>
        <div id="resizable" className={classes.productDescription}>
          <div className="resizable-border" draggable="true" onDragStart={initial} onDrag={resize} />
          <ProductDescription />
        </div>

        <div className={classes.title}>
          <PageHeader title="Novo produto" backAction={handlePrior} />

          <Typography variant={'body1'}>
            <span className={classes.step}>{currentStep?.order}</span>
            {currentStep?.description}
          </Typography>
        </div>

        <div className={classes.container} id="container">
          {currentStep?.id === 'STEP_1' && <CategorySelectStep />}
          {currentStep?.id === 'STEP_2' && <ProductFormStep />}
          {currentStep?.id === 'STEP_3' && <ProductPricesStep />}
          {currentStep?.id === 'STEP_4' && <ProductIngredientStep />}
          {currentStep?.id === 'STEP_5' && <ProductAdditionalStep />}
          {currentStep?.id === 'STEP_6' && product.category.is_pizza ? (
            <ProductComplementPizza />
          ) : (
            currentStep?.id === 'STEP_6' && !product.category.is_pizza && <ProductComplement />
          )}
          {currentStep?.id === 'STEP_7' && <ProductAvailability availability={product.availability} />}
          {currentStep?.id === 'STEP_8' && <ProductFiscalStep taxRules={taxRules} />}
          {currentStep?.id === 'STEP_9' && <ProductPrintersStep printers={printers} />}
          {currentStep?.id === 'STEP_10' && <ProductDoneStep saving={saving} handleSubmit={handleSubmit} />}

          {currentStep && currentStep.order >= 2 && <ProductButtons width={width} />}
        </div>
      </div>
    </ProductContextProvider>
  );
};

export default Product;
